export const Specialisation = {
  BachelorDataEtIA: "BachelorDataEtIA",
  BachelorCybersecurite: "BachelorCybersecurite",
  BachelorDeveloppementWeb: "BachelorDeveloppementWeb",
  BachelorUIProductDesigner: "BachelorUIProductDesigner",
  BachelorWebmarketingEtUX: "BachelorWebmarketingEtUX",
  ExecutiveMBADataEtIA: "ExecutiveMBADataEtIA",
  ExecutiveMBADigitalMarketing: "ExecutiveMBADigitalMarketing",
  ExecutiveMBAEcommerce: "ExecutiveMBAEcommerce",
  ExecutiveMBATechManagement: "ExecutiveMBATechManagement",
  MastereCTOEtTechLead: "MastereCTOEtTechLead",
  MastereCybersecurite: "MastereCybersecurite",
  MastereDataEtIA: "MastereDataEtIA",
  MastereDataPrivacyOfficer: "MastereDataPrivacyOfficer",
  MastereMarketingDigitalEtUX: "MastereMarketingDigitalEtUX",
  MastereProductDesignerExpert: "MastereProductDesignerExpert",
  MastereProductManager: "MastereProductManager",
  PrepaMastereCybersecurite: "PrepaMastereCybersecurite",
  PrepaMastereDigital: "PrepaMastereDigital",
  PrepaMastereMarketingDesign: "PrepaMastereMarketingDesign",
  PrepaMastereTechData: "PrepaMastereTechData",
  ProgrammeGrandeEcole: "ProgrammeGrandeEcole",
}

export const Months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
]